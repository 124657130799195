import React, { Children, ReactComponentElement, useState } from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import TreeNode, { Props } from "./TreeNode";
import { ClassifierProps } from "api/Classifier";

type Node = {
  id: number;
  name: string;
  children?: Node[];
};
type NodeRender = {
  node: Node;
  indent: number;
};
type Props1 = {
  nodes: (Node & { children?: Node[] })[];
  nodeComponent: React.FunctionComponent<Props>;
  onCreate: (props: { parentId: number }) => {};
  onRemove: (id: number) => {};
  onUpdate: (props: { id: number; title: string }) => {};
};

const prepareData = (nodes: Node[], collapsedNodes: Set<number>) => {
  const arr = nodes.map((el) => ({ node: el, indent: 0 }));
  arr.sort((a, b) =>
    a.node.name === b.node.name
      ? a.node.id - b.node.id
      : a.node.name < b.node.name
      ? -1
      : 1
  );
  const result: NodeRender[] = [];
  while (arr.length > 0) {
    const data = arr.pop()!;
    result.push(data);
    const indent = data.indent + 1;

    if (collapsedNodes.has(data.node.id)) {
      const children = [...(data.node.children || [])];
      children.sort((a, b) =>
        a.name === b.name ? a.id - b.id : a.name < b.name ? -1 : 1
      );
      children.reverse().forEach((el) => {
        arr.push({ node: el, indent: indent });
      });
    }
  }
  return result;
};

const Tree = (props: Props1) => {
  const [collapse, setCollapsed] = useState<Set<number>>(new Set());

  const nodesForRender = prepareData(props.nodes, collapse);
  const handleCollpsed = (id: number) => {
    setCollapsed((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  };
  console.log(nodesForRender);
  return (
    <Container>
      <Row>
        <Col>
          {nodesForRender.map((el: NodeRender) => (
            <TreeNode
              key={el.node.id}
              space={el.indent}
              title={el.node.name}
              id={el.node.id}
              onEdit={props.onUpdate}
              onCreate={props.onCreate}
              onRemove={props.onRemove}
              onCollapsed={handleCollpsed}
              collapse={collapse.has(el.node.id)}
              hasChildren={!!el.node.children?.length}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Tree;
