import fetchApi from "./fetch";

export type CreateSuppliesTargetsDto = {
  warehouse_id: number;
  box_type_id: number;
  min_coefficient: number;
  min_day: number;
  max_day: number;
  barcode: string;
  products_count: number;
  pallet_count: number | null;
  notification: boolean;
  reservation: boolean;
};

export type UpdateSuppliesTargetsDto = Partial<CreateSuppliesTargetsDto>;

export type SuppliesTarget = CreateSuppliesTargetsDto & {
  id: number;
  created_at: string;
  deleted_at: string | null;
};
export type SuppliesListItem = SuppliesTarget & {
  draft_count?: number | null;
  planed_count?: Record<
    string,
    { supplyId: number; coefficient: number }[]
  > | null;
};

export type Warehouse = {
  id: number;
  name: string;
};
export type BoxType = {
  value: number;
  label: string;
};

export const fetchSuppliesTargets = async () => {
  const result = await fetchApi("/api/supplies", {
    method: "GET",
  });
  const data = (await result.json()) as SuppliesTarget[];
  return data;
};

export const fetchSuppliesWarehouses = async () => {
  const result = await fetchApi("/api/supplies/warehouses", {
    method: "GET",
  });
  const data = (await result.json()) as Warehouse[];
  return data;
};

export const fetchSuppliesBoxTypes = async () => {
  const result = await fetchApi("/api/supplies/box_types", {
    method: "GET",
  });
  const data = (await result.json()) as BoxType[];
  return data;
};

export const fetchSupplyList = async () => {
  const result = await fetchApi("/api/supplies/get_supply_list", {
    method: "GET",
  });
  const data = (await result.json()) as SuppliesListItem[];
  return data;
};

export const planSupply = async (targetId: number, date: string) => {
  const result = await fetchApi(`/api/supplies/${targetId}/plan_supply`, {
    method: "POST",
    body: JSON.stringify({ date }),
  });
  const data = (await result.json()) as SuppliesListItem[];
  return data;
};

export const fetchSupplyDraft = async () => {
  const result = await fetchApi("/api/supplies/get_supply_draft", {
    method: "GET",
  });
  const data = (await result.json()) as SuppliesTarget[];
  return data;
};

export const createSuppliesTargets = async (body: CreateSuppliesTargetsDto) => {
  const result = await fetchApi("/api/supplies", {
    method: "POST",
    body: JSON.stringify(body),
  });
  const data = (await result.json()) as SuppliesTarget;
  return data;
};

export const updateSuppliesTargets = async (
  id: number,
  body: UpdateSuppliesTargetsDto
) => {
  const result = await fetchApi(`/api/supplies/${id}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  });
  const data = (await result.json()) as SuppliesTarget;
  return data;
};

export const deleteSuppliesTargets = async (id: number) => {
  const result = await fetchApi(`/api/supplies/${id}`, {
    method: "DELETE",
  });
  const data = (await result.json()) as SuppliesTarget;
  return data;
};

export const createSupplyDraft = async (id: number) => {
  const result = await fetchApi(`/api/supplies/${id}/create_supply_draft`, {
    method: "POST",
  });
  const data = await result.json();
  return data;
};
