import React from "react";
import { Nav } from "react-bootstrap";
import styled, { css } from "styled-components";
import colors from "../colors";
import { NavLink, useLocation } from "react-router-dom";

const HeaderSticky = styled.div`
  background: ${colors.main};
  position: fixed;
  top: 0;
  left: 0;
  height: 53px;
  width: 100%;
  z-index: 100;
`;

const HeaderWrap = styled.div`
  padding-bottom: 53px;

  @media screen and (max-width: 1024px) {
    ${HeaderSticky} {
      position: relative;
    }
    padding-bottom: 0;
  }
`;
const Content = styled.div`
  max-width: 1280px;
  min-width: 1024px;
  margin: auto;
  padding: 8px 28px;
`;
const StyledLink = styled(NavLink)<{ isActive?: boolean }>`
  color: ${colors.white};
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px;
  text-decoration: none;
  &:active,
  &:hover {
    color: ${colors.second};
  }
  ${(props) => {
    console.log({ props });
    return (
      props.isActive &&
      css`
        background: ${colors.hover};
        border-radius: 4px;
      `
    );
  }}
`;
const buttons = [
  { path: "/answers", label: "Ответы на отзывы" },
  // { path: "/sales", label: "Продажи" },
  { path: "/remnants", label: "Остатки" },
  // { path: "/deliveries", label: "Поставки" },
  { path: "/supplies", label: "Поставки" },
  // { path: "/fbs", label: "FBS" },
  { path: "/price_loader", label: "Цены" },
  { path: "/price_monitoring", label: "Мониторинг цен" },
  { path: "/settings", label: "Настройки" },
];
const Header = () => {
  const location = useLocation();

  return (
    <HeaderWrap>
      <HeaderSticky>
        <Content>
          <Nav>
            {buttons.map((el) => (
              <StyledLink
                key={el.path}
                to={el.path}
                isActive={location.pathname.indexOf(el.path) === 0}
              >
                {el.label}
              </StyledLink>
            ))}
          </Nav>
        </Content>
      </HeaderSticky>
    </HeaderWrap>
  );
};

export default Header;
