import { Table, Row, Col, Typography } from "antd";
import React from "react";
import { BotSettings } from "./BotSettings";
import { SuppliesList } from "./SuppliesList";

const Supplies = () => {
  return (
    <>
      <Typography.Title level={4}>Настройки для бота</Typography.Title>
      <BotSettings />
      <Typography.Title level={4}>Мои поставки</Typography.Title>
      <SuppliesList />
    </>
  );
};

export default Supplies;
