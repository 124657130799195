import React, { useEffect, useMemo, useRef, useState } from "react";
import { TvView } from "./useTvView";
import { createLogLink, getGroup } from "api/PriceMonitoring";
import { ProductGroup } from "PriceMonitoring/modal";
import { Row } from "antd";
import { getPassedTime, sortProducts } from "PriceMonitoring/helpers";

import {
  ColRightAlign,
  GroupTitle,
  HeadrCol,
  MainCol,
  StyledRow,
  Wrap,
  Table,
  Handle,
} from "./styled";
import wb from "./wb.png";
import ozon from "./ozon.png";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import GroupItem from "./GroupItem";

const round = (price: number) => Math.round(price * 10) / 10;

const Group = (props: {
  data: TvView["groups"][number];
  timestamp: string;
  categoryId: number;
}) => {
  const blockDrag = useRef(false);
  const [source, setSource] = useState<ProductGroup["products"]>([]);
  const loadData = async () => {
    const result = await getGroup(props.data.id);
    setSource(result.products);
  };
  const items = useMemo(() => {
    if (!source) return [];
    const items = sortProducts(source);
    return items;
  }, [source]);

  useEffect(() => {
    loadData();
  }, [props.data.id, props.timestamp]);
  const lastTime = items
    .map((el) =>
      el.links.map((el) =>
        el.last_price_log?.completed_date
          ? new Date(el.last_price_log?.completed_date).getTime()
          : null
      )
    )
    .flat()
    .filter((el) => el)
    .map((el) => el!);
  let maxDate = Math.min(...lastTime);
  const minData = Math.max(...lastTime);
  const curentTime = new Date();
  const minDataStr = getPassedTime(new Date(minData), curentTime);
  const maxDataStr = getPassedTime(new Date(maxDate), curentTime);

  return (
    <Wrap
      draggable
      data-group-id={props.data.id}
      data-category-id={props.categoryId}
      onDragStart={(e) => {
        blockDrag.current && e.preventDefault();
      }}
      onMouseDown={(e) =>
        (blockDrag.current = !(e.target as HTMLElement).closest(".drag_target"))
      }
    >
      <Handle className={"drag_target"} />
      <GroupTitle>{props.data.name}</GroupTitle>
      <Row gutter={[6, 12]}>
        <HeadrCol span={10} style={{ paddingLeft: 20 }}>
          <HistoryOutlined
            onClick={() => {
              createLogLink(props.data.id);
            }}
          />{" "}
          {minDataStr}{" "}
          {minDataStr !== maxDataStr
            ? ` -  
            ${getPassedTime(new Date(maxDate), curentTime)}`
            : ""}
        </HeadrCol>
        <MainCol span={2}></MainCol>
        <MainCol span={5} style={{ textAlign: "center" }}>
          ₽
        </MainCol>
        <MainCol span={5} style={{ textAlign: "center" }}>
          ₽/шт
        </MainCol>
        <MainCol span={2}></MainCol>
      </Row>
      <Table>
        {items?.map((el) => (
          <GroupItem data={el} />
        ))}
      </Table>
    </Wrap>
  );
};

export default Group;
