import styled from "styled-components";
import { Input as InputOrig } from "antd";

export const NodeWrap = styled.div`
  margin: 4px;
`;

export const NodeInner = styled.div<{ root?: boolean; space: number }>`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #e6fffb;
  position: relative;
  display: inline-flex;
`;

export const NodeSpace = styled.div`
  position: relative;
  display: inline-block;
  width: 8px;
  /* content: " "; */
  white-space: nowrap;
  &:after {
    content: "";

    position: absolute;
    /* left: -10px; */
    /* width: 1px; */
    background-color: #cccccc;
    height: 100%;
    /* top: -4px; */
    /* 
    border-top: 0;
    border-right: 0; */
  }
`;

export const Actions = styled.div`
  display: inline-block;
  flex: 0 0 auto;
`;

export const Input = styled(InputOrig)`
  /* display: inline-block; */
  /* height: 20px; */
`;
