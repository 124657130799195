import { useEffect, useState } from "react";
import {
  CreateSuppliesTargetsDto,
  SuppliesTarget,
  fetchSuppliesTargets,
  fetchSuppliesWarehouses,
  createSuppliesTargets,
  updateSuppliesTargets,
  fetchSuppliesBoxTypes,
  deleteSuppliesTargets,
  planSupply,
  fetchSupplyList,
  SuppliesListItem,
  createSupplyDraft,
} from "api/Supplies";
import useFormStateWithErrors from "shared/utils/useFormStateWithErrors";

export function useSuppliesListData() {
  const [list, setList] = useState<SuppliesListItem[]>([]);
  const [warehouseList, setWarehouseList] = useState<
    { value: number; label: string }[]
  >([]);
  const [boxTypes, setBoxTypes] = useState<{ value: number; label: string }[]>(
    []
  );

  const fetchList = async () => {
    const data = await fetchSupplyList();
    console.log(data);
    if (data instanceof Array) {
      setList(data);
    } else {
      setList([]);
    }
  };

  const fetchWawrehouseList = async () => {
    const data = await fetchSuppliesWarehouses();
    setWarehouseList(data.map((el) => ({ value: el.id, label: el.name })));
  };
  const fetchBoxTypesList = async () => {
    const data = await fetchSuppliesBoxTypes();
    setBoxTypes(data);
  };

  const plan = async (targetId: number, date: string) => {
    const data = await planSupply(targetId, date);
    // await fetchList();
  };
  const sendCreateSupplyDraft = async (id: number) => {
    const res = await createSupplyDraft(id);
    console.log(res);
  };
  useEffect(() => {
    fetchList();
    fetchWawrehouseList();
    fetchBoxTypesList();
  }, []);

  return { list, warehouseList, boxTypes, plan, sendCreateSupplyDraft };
}
export type SuppliesTargetForm = Record<
  keyof Omit<
    SuppliesTarget,
    "notification" | "reservation" | "id" | "created_at" | "deleted_at"
  >,
  string
> & { notification: boolean; reservation: boolean; id: number | null };

export const defaultValue: SuppliesTargetForm = {
  id: null,
  warehouse_id: "",
  box_type_id: "",
  min_coefficient: "",
  min_day: "",
  max_day: "",
  barcode: "",
  products_count: "",
  pallet_count: "",
  notification: false,
  reservation: false,
};

const errors: Record<keyof SuppliesTargetForm, string> = {
  id: "",
  warehouse_id: "",
  box_type_id: "",
  min_coefficient: "",
  min_day: "",
  max_day: "",
  barcode: "",
  products_count: "",
  pallet_count: "",
  notification: "",
  reservation: "",
};

export const useSuppliesTargetState = () => {
  return useFormStateWithErrors<SuppliesTargetForm>(defaultValue, errors);
};
