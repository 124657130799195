import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  createGroup,
  getAll,
  getGroup,
  updateLog,
  createProduct,
  deleteGroup,
  deleteProduct,
  createLogLink,
} from "api/PriceMonitoring";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Check, Edit, FieldTitle, FieldWrap, Close, Delete } from "./styled";
import { Space } from "shared/ui";
import Title from "antd/es/typography/Title";
import { useGroupEditorState } from "./useGroupEditorState";
import { usePriceMonitoringContext } from "../Contex";
import { ProductGroup } from "PriceMonitoring/modal";
import ProductList from "./ProductList";
import { confirmPopup } from "shared/ConfirmPopup";

const GroupEditor = (props: {
  id: number;
  productCategories: { id: number; name: string }[];
}) => {
  const [source, setSource] = useState<ProductGroup | null>(null);
  const context = usePriceMonitoringContext();

  const form = useGroupEditorState();
  const [editableName, setEditableName] = useState(false);
  const { editGroup } = usePriceMonitoringContext();
  const handleSave = async () => {
    await editGroup(props.id, {
      name: form.values.name,
      id: props.id,
      products: [],
      category: form.values.category,
    });
    setEditableName(false);
  };

  const handleDelete = async () => {
    const result = await confirmPopup(<>Уверены, что хотите удалить группу?</>);
    if (result) {
      await context.removeGroup(props.id);
    }
  };

  const handleDeleteProduct = async (id: number) => {
    const result = await confirmPopup(<>Уверены, что хотите удалить товар?</>);
    if (result) {
      const data = await deleteProduct(id);
      if (data) {
        setSource({
          ...source!,
          products: source!.products.filter((el) => el.id !== id),
        });
      }
    }
  };

  const handleCancelNameEdit = () => {
    form.setValue("name")(source!.name);
    setEditableName(false);
  };
  const fetchValues = async () => {
    const result = await getGroup(props.id);
    setSource(result);
    form.setValues({ name: result.name, category: result.category });
  };

  const addProduct = async () => {
    const result = await createProduct({ groups: [{ id: props.id }] });
    setEditableName(false);
    setSource({ ...source!, products: [result, ...source!.products] });
  };

  useEffect(() => {
    fetchValues();
  }, [props.id]);

  if (!source) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col>
          <Title level={3}>
            Группа{" "}
            {!editableName ? (
              <>
                {form.values.name}{" "}
                <Edit onClick={() => setEditableName(true)} />
                <Space size={8} inline />
                <Delete onClick={() => handleDelete()} />
              </>
            ) : (
              <>
                <Input
                  value={form.values.name}
                  onChange={(value) =>
                    form.setValue("name")(value.target.value)
                  }
                  style={{
                    display: "inline-block",
                    width: 400,
                    verticalAlign: "middle",
                  }}
                />{" "}
                <Check onClick={() => handleSave()}></Check>
                <Close onClick={() => handleCancelNameEdit()}></Close>
              </>
            )}{" "}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Категория" style={{ marginRight: 16 }}>
            {!editableName ? (
              form.values.category?.name || "Не выбрана"
            ) : (
              <Select
                placeholder="Выбрать категорию"
                style={{ width: 300 }}
                value={form.values.category?.id}
                optionFilterProp="label"
                allowClear
                showSearch
                onChange={(e) =>
                  form.setValue("category")(
                    props.productCategories.find((el) => el.id === e) || null
                  )
                }
                options={props.productCategories.map((el) => ({
                  value: el.id,
                  label: el.name,
                }))}
              ></Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => addProduct()}>Добавить товар</Button>
          <Button
            onClick={() => createLogLink(props.id)}
            style={{ marginLeft: 8 }}
          >
            Обновить цены сейчас
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ProductList
            data={source.products}
            onChangeProduct={context.changeSelectedProduct}
            onDeleteProduct={handleDeleteProduct}
          />
        </Col>
      </Row>
    </>
  );
};
export default GroupEditor;
