import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button, Switch } from "antd";
import { Select, Checkbox, Input, InputNumber, Popconfirm } from "antd";

import { Modal } from "antd";
import {
  defaultValue,
  useBotSettingsData,
  useSuppliesTargetState,
} from "./BotSettingsHooks";
import Icon, {
  CheckCircleFilled,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";

export const BotSettings = () => {
  const {
    list,
    warehouseList,
    boxTypes,
    create,
    update,
    remove,
    sendCreateSupplyDraft,
  } = useBotSettingsData();
  const { values, setValue, setValues, setError } = useSuppliesTargetState();
  const [modalOpen, setModalOpen] = useState(false);

  const edit = (id: number) => {
    const el = list.find((el) => el.id === id);
    if (!el) return;
    setValues({
      id: el.id,
      warehouse_id: String(el.warehouse_id),
      box_type_id: String(el.box_type_id),
      min_coefficient: String(el.min_coefficient),
      min_day: String(el.min_day),
      max_day: String(el.max_day),
      barcode: String(el.barcode),
      products_count: String(el.products_count),
      pallet_count: el.pallet_count === null ? "" : String(el.pallet_count),
      notification: el.notification,
      reservation: el.reservation,
    });
    setModalOpen(true);
  };
  const deleteTarget = (id: number) => {
    remove(id);
  };
  const newForm = () => {
    setValues({
      ...defaultValue,
    });
    setModalOpen(true);
  };
  return (
    <>
      <div>
        <Button
          type="primary"
          onClick={newForm}
          style={{ display: "inline-block", width: "auto" }}
        >
          Добавить цели
        </Button>
      </div>
      <Table
        columns={[
          { dataIndex: "warehouseName", title: "Склад" },
          { dataIndex: "box_type_id", title: "Тип" },
          { dataIndex: "min_coefficient", title: "макс. коэф." },
          { dataIndex: "min_day", title: "Мин. дней" },
          { dataIndex: "max_day", title: "Макс. дней" },
          { dataIndex: "notification", title: "Вкл. уведомления" },
          { dataIndex: "reservation", title: "Вкл. бронирование" },
          { dataIndex: "actions", title: "" },
        ]}
        dataSource={list.map((el) => ({
          key: el.id,
          warehouseName:
            warehouseList.find((w) => w.value === el.warehouse_id)?.label ||
            el.warehouse_id,
          box_type_id:
            boxTypes.find((b) => b.value === el.box_type_id)?.label ||
            el.box_type_id,
          min_coefficient: String(el.min_coefficient),
          min_day: String(el.min_day),
          max_day: String(el.max_day),
          barcode: String(el.barcode),
          products_count: String(el.products_count),
          notification: el.notification ? (
            <CheckCircleFilled style={{ color: "#52c41a" }} />
          ) : (
            <StopOutlined style={{ color: "#f81d22" }} />
          ),
          reservation: el.reservation ? (
            <CheckCircleFilled style={{ color: "#52c41a" }} />
          ) : (
            <StopOutlined style={{ color: "#f81d22" }} />
          ),
          actions: (
            <div style={{ width: 120 }}>
              <Button
                onClick={() => edit(el.id)}
                style={{ display: "inline-block" }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                onConfirm={() => deleteTarget(el.id)}
                title={"Удалить?"}
              >
                <Button style={{ display: "inline-block" }}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          ),
        }))}
      ></Table>
      <Modal
        open={modalOpen}
        okText={"Сохранить"}
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={() => {
          if (values.id !== null) {
            update(values.id, {
              warehouse_id: +values.warehouse_id,
              box_type_id: +values.box_type_id,
              min_coefficient: +values.min_coefficient,
              min_day: +values.min_day,
              max_day: +values.max_day,
              barcode: values.barcode,
              products_count: +values.products_count,
              pallet_count: values.pallet_count.length
                ? +values.pallet_count
                : null,
              notification: values.notification,
              reservation: values.reservation,
            }).then(() => {
              setModalOpen(false);
            });
          } else
            create({
              warehouse_id: +values.warehouse_id,
              box_type_id: +values.box_type_id,
              min_coefficient: +values.min_coefficient,
              min_day: +values.min_day,
              max_day: +values.max_day,
              barcode: values.barcode,
              products_count: +values.products_count,
              pallet_count: values.pallet_count.length
                ? +values.pallet_count
                : null,
              notification: values.notification,
              reservation: values.reservation,
            }).then(() => {
              setModalOpen(false);
            });
        }}
      >
        <Form size="small">
          <Form.Item label="Склад">
            <Select
              options={warehouseList}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={
                values.warehouse_id &&
                warehouseList.find(
                  (el) => String(el.value) === values.warehouse_id
                )
              }
              onSelect={(_, option) => {
                setValue("warehouse_id")(String(option.value));
              }}
            ></Select>
          </Form.Item>
          <Form.Item label="Тип упаковки">
            <Select
              options={boxTypes}
              value={
                values.box_type_id &&
                boxTypes.find((el) => String(el.value) === values.box_type_id)
              }
              onSelect={(_, option) => {
                setValue("box_type_id")(String(option.value));
              }}
            ></Select>
          </Form.Item>
          <Form.Item label="Макс. коэф.">
            <InputNumber
              value={values.min_coefficient}
              onChange={(val) => {
                setValue("min_coefficient")(String(val));
              }}
            />
          </Form.Item>
          <Form.Item label="Мин. дней">
            <InputNumber
              value={values.min_day}
              onChange={(val) => {
                setValue("min_day")(String(val));
              }}
            />
          </Form.Item>
          <Form.Item label="Макс. дней">
            <InputNumber
              value={values.max_day}
              onChange={(val) => {
                setValue("max_day")(String(val));
              }}
            />
          </Form.Item>
          <Form.Item label="Баркод">
            <Input
              value={values.barcode}
              onChange={(val) => {
                setValue("barcode")(val.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Количество товар">
            <InputNumber
              value={values.products_count}
              onChange={(val) => {
                setValue("products_count")(String(val));
              }}
            />
          </Form.Item>
          <Form.Item label="Количество паллет">
            <InputNumber
              value={values.pallet_count}
              onChange={(val) => {
                setValue("pallet_count")(String(val));
              }}
            />
          </Form.Item>
          <Form.Item label="Уведомления" valuePropName="checked">
            <Switch
              checked={values.notification}
              onChange={(val) => {
                setValue("notification")(val);
              }}
            />
          </Form.Item>
          <Form.Item label="Автобронь" valuePropName="checked">
            <Switch
              checked={values.reservation}
              onChange={(val) => {
                setValue("reservation")(val);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
