import React, { useEffect, useState } from "react";
import { Table, Row, Col, Form, Button, Switch } from "antd";
import { Select, Checkbox, Input, InputNumber, Popconfirm } from "antd";

import { useSuppliesListData } from "./SuppliesListHooks";

export const SuppliesList = () => {
  const { list, warehouseList, boxTypes, plan, sendCreateSupplyDraft } =
    useSuppliesListData();
  const dates = getDateRange(new Date(), 14);

  const dataSource = list.map((el) => ({
    ...dates.reduce(
      (acc, date) => ({
        ...acc,
        ["date" + date.replace(/[^\d]/g, "")]: (
          <>
            К:&nbsp;{`-1%`} <br /> Б:&nbsp;
            {el.planed_count?.[date]?.map((el1) => el1.coefficient).length}
            <br />
            <Button
              size="small"
              onClick={() => {
                plan(el.id, date);
              }}
            >
              +
            </Button>
          </>
        ),
      }),
      {}
    ),
  }));

  return (
    <>
      <Table
        columns={[
          { dataIndex: "warehouseName", title: "Склад" },
          { dataIndex: "box_type_id", title: "Тип" },
          { dataIndex: "draft", title: "Черновик" },
          ...dates.map((el) => ({
            dataIndex: "date" + el.replace(/[^\d]/g, ""),
            title: el.split("-").slice(1).reverse().join("."),
          })),
        ]}
        dataSource={list.map((el, i) => ({
          key: el.id,
          warehouseName:
            warehouseList.find((w) => w.value === el.warehouse_id)?.label ||
            el.warehouse_id,
          box_type_id:
            boxTypes.find((b) => b.value === el.box_type_id)?.label ||
            el.box_type_id,
          draft: (
            <>
              {String(el.draft_count)}
              <br />{" "}
              <Button onClick={() => sendCreateSupplyDraft(el.id)}>+</Button>
            </>
          ),
          ...dataSource[i],
        }))}
      ></Table>
    </>
  );
};

function getDateRange(startDate: Date, count: number) {
  return new Array(count).fill(0).map((el, i) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    return date.toJSON().slice(0, 10);
  });
}
